import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { DsTextIndicatorModule } from '@design-system/components/text-indicator';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'opm-error-info',
  templateUrl: './error-info.component.html',
  styleUrls: ['./error-info.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FlexLayoutModule,
    MatCardModule,
    MatIconModule,
    DsTextIndicatorModule,
  ],
})
export class ErrorInfoComponent implements OnInit {
  @Input() highlight = false;
  @Input() errorText = '';
  @Input() hasTelematics = true;
  @Input() title = null;
  showOfflineMessage: boolean;

  ngOnInit() {
    this.showOfflineMessage = !navigator.onLine;
  }
}
