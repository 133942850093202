<ng-container *ngIf="!userService.isAuthorized" [gaCategoryGroup]="'Landing'">
  <div fxLayout="column" [fxLayoutGap]="1 | dsSpacing">
    <h1 data-e2e="landing-headline" class="no-margin-top">
      {{ 'operator_monitor.landing.welcome' | translate | uppercase }}
    </h1>
    <h3 class="no-margin-top">
      {{ 'operator_monitor.landing.free_features' | translate | uppercase }}
    </h3>
    <div
      fxLayout="row wrap"
      fxLayout.lt-md="column"
      [fxLayoutGap]="(1 | dsSpacing) + ' grid'"
      fxLayoutAlign="start stretch"
      class="tiles"
    >
      @for (feature of freeFeatures; track feature.name; let odd = $odd) {
        <div fxFlex="1 1 0">
          <mat-card
            data-e2e="free-features-button"
            [gaClickListener]="'Navigate to ' + feature.name"
            class="full-height"
            ><mat-card-content
              [fxLayout]="odd ? 'row-reverse' : 'row'"
              class="no-padding"
            >
              <img
                [src]="feature.image"
                [alt]="feature.name"
                [class]="(odd ? 'right' : 'left') + ' card-image'"
              />
              <div
                fxLayout="column wrap"
                fxLayoutAlign="space-between center"
                [class]="(odd ? 'left-text' : 'right-text') + ' padding'"
              >
                <div>
                  <h3
                    class="no-margin-top"
                    [attr.data-cy]="feature.name + '-title'"
                  >
                    {{ feature.name | translate }}
                  </h3>
                  <p>{{ feature.description | translate }}</p>
                </div>
                <button
                  [routerLink]="[feature.link]"
                  mat-stroked-button
                  [fxFlexAlign]="odd ? 'start' : 'end'"
                  [attr.data-cy]="feature.name + '-button'"
                >
                  {{ 'common.open' | translate }}
                </button>
              </div>
            </mat-card-content></mat-card
          >
        </div>
      }
    </div>
    <h3>
      {{
        'operator_monitor.landing.registered_features' | translate | uppercase
      }}
    </h3>
    <div
      fxLayout="row wrap"
      fxLayout.lt-md="column"
      [fxLayoutGap]="(1 | dsSpacing) + ' grid'"
      fxLayoutAlign="start stretch"
      class="tiles"
    >
      @for (feature of registeredFeatures; track feature.name; let odd = $odd) {
        <div fxFlex.xs="100%" fxFlex.gt-xs="50%">
          <mat-card class="disable full-height"
            ><mat-card-content
              [fxLayout]="odd ? 'row-reverse' : 'row'"
              class="no-padding"
            >
              <img
                [src]="feature.image"
                [alt]="feature.name"
                [class]="(odd ? 'right' : 'left') + ' card-image'"
              />
              <div
                fxLayout="column wrap"
                [class]="
                  (odd ? 'left-text' : 'right-text') +
                  ' padding registered full-width'
                "
              >
                <h3
                  class="no-margin-top"
                  [attr.data-cy]="feature.name + '-title'"
                >
                  {{ feature.name | translate }}
                </h3>
                <p>{{ feature.description | translate }}</p>
              </div>
            </mat-card-content></mat-card
          >
        </div>
      }
    </div>
    <div
      class="sticky"
      fxLayout="row"
      [fxLayoutGap]="1 | dsSpacing"
      fxLayoutAlign="stretch center"
    >
      <button
        fxFlex
        mat-raised-button
        color="primary"
        (click)="userService.login()"
        class="no-padding"
        data-cy="landing-login"
        [gaClickListener]="'Login'"
      >
        {{ 'sign_in.sign_in' | translate }}
      </button>
      <a
        fxFlex
        mat-raised-button
        class="no-padding"
        (click)="openCreateAccount()"
        [gaClickListener]="'Navigate to register'"
        data-cy="register-button"
      >
        {{ 'sign_in.create_account' | translate }}
      </a>
    </div>
  </div>

  <mat-card>
    <mat-card-header
      ><mat-card-title
        ><h3 class="no-margin">PALFINGER Connected</h3></mat-card-title
      ></mat-card-header
    >
    <mat-card-content class="iframe-card">
      <iframe
        class="promo full-width"
        [src]="promoVideo | pdYouTubeUrl | pdSafe"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
        data-e2e="promo-video"
        data-cy="promo-video"
      ></iframe>
    </mat-card-content>
  </mat-card>
</ng-container>
