import {
  RetrofittingOptions,
  ContractsOverviewResponse,
  ContractCategory,
} from '@connected/fleet-service/models';
import {
  ContractStatusEnum,
  ConnectedContractTypes,
  UpgradeableContracts,
  TelematicState,
  UpgradeableState,
} from '../models';
import { DateTime } from '@paldesk/shared-lib/utils/date-utils';
import { TranslateService } from '@ngx-translate/core';

const RetrofittingOptionsEnum = RetrofittingOptions;

const activeConnectedPlusContractCondition = (
  contract: ContractsOverviewResponse,
) =>
  contract?.contract_status === ContractStatusEnum.ACTIVE &&
  (contract?.contract_type === ConnectedContractTypes.CONNECTED_PLUS ||
    contract?.contract_type === ConnectedContractTypes.CONNECTED_PLUS_TRIAL ||
    contract?.contract_type ===
      ConnectedContractTypes.CONNECTED_PLUS_EARLY_ADOPTER);

export enum ShowUpgradeEnum {
  None = 'None',
  Retrofittable = 'Retrofittable',
  NotRetrofitable = 'NotRetrofitable',
}

export function checkEquipmentForUpgradeInfo(
  contracts: ContractsOverviewResponse[] | null | undefined,
  hasTelematic: boolean,
  retrofittable?: RetrofittingOptions,
): ShowUpgradeEnum {
  if (
    (hasTelematic || retrofittable === RetrofittingOptionsEnum.Yes) &&
    (!contracts?.length ||
      (!!contracts?.length &&
        !contracts.some(activeConnectedPlusContractCondition)))
  ) {
    return ShowUpgradeEnum.Retrofittable;
  } else return ShowUpgradeEnum.None;
}

export function checkEquipmentForActiveConnectedPlusContracts(
  contracts: ContractsOverviewResponse[] | null | undefined,
): boolean {
  return (
    !!contracts?.length && contracts.some(activeConnectedPlusContractCondition)
  );
}

export function getConnectedContracts(
  contracts: ContractsOverviewResponse[],
): ContractsOverviewResponse[] {
  return contracts.filter(
    (contract) =>
      contract?.contract_category === ContractCategory.Connected &&
      contract?.contract_status === ContractStatusEnum.ACTIVE &&
      (contract?.contract_type === ConnectedContractTypes.CONNECTED_PLUS ||
        contract?.contract_type ===
          ConnectedContractTypes.CONNECTED_PLUS_TRIAL ||
        contract?.contract_type ===
          ConnectedContractTypes.CONNECTED_PLUS_EARLY_ADOPTER),
  );
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export function filterUpgradableContracts(
  data: ContractsOverviewResponse[],
  isForJobPlanner = false,
): UpgradeableContracts[] {
  let upgradableContracts: UpgradeableContracts[] = [];
  //only cranes
  if (isForJobPlanner)
    data = data.filter((contract) => contract.equipment_type_icon === '001');

  // Filter by CONNECTED contract
  const contracts = filterByConnectedContract(data, isForJobPlanner);

  // Filter by retrofittable
  const retrofitableContracts = isForJobPlanner
    ? []
    : filterByRetrofittableEquipment(contracts);

  // Filter by CONNECTED PLUS & CONNECTED PLUS TRIAL contract
  const connectedPlusContracts = filterByConnectedPlusContract(contracts);

  // Filter by active CONNECTED PLUS Trial contract
  const connectedPlusTrialContracts =
    filterByActiveConnectedPlusTrialContract(contracts);

  // Filter by active (not expiring soon) CONNECTED PLUS & CONNECTED PLUS TRIAL contract
  const activeConnectedPlusContracts = filterByActiveConnectedPlusContracts(
    connectedPlusContracts,
  );

  // Filter by expire soon
  const expiredSoonContracts = filterByExpireSoonContract(
    connectedPlusContracts,
  );

  // Filter by expired
  const expiredContracts = filterByExpiredContract(
    connectedPlusContracts,
  ).filter(
    (contract) =>
      !activeConnectedPlusContracts.some(
        (activeContract) =>
          activeContract.equipment_number === contract.equipment_number,
      ),
  );

  // Filter by no contract
  const noContractContracts = filterByNoContract(contracts);

  if (connectedPlusTrialContracts.length) {
    upgradableContracts = connectedPlusTrialContracts.map((contract) => ({
      carrier: contract.carrier,
      equipment_number: contract.equipment_number,
      equipment_type_icon: contract.equipment_type_icon,
      equipment: contract.equipment,
      telematic_state:
        contract.is_retrofittable &&
        !contract.has_telematics &&
        !isForJobPlanner
          ? TelematicState.Retrofitable
          : contract.has_telematics
            ? TelematicState.Telematic
            : TelematicState.NoTelematic,
      upgradeable_state: UpgradeableState.ActiveTrial,
      service_partner_info: contract.service_partner_info,
      isSccValid: false,
    }));
  }

  if (retrofitableContracts.length) {
    const mergeContracts = retrofitableContracts.map((contract) => ({
      carrier: contract.carrier,
      equipment_number: contract.equipment_number,
      equipment_type_icon: contract.equipment_type_icon,
      equipment: contract.equipment,
      telematic_state: TelematicState.Retrofitable,
      upgradeable_state:
        contract.contract_status === ContractStatusEnum.ACTIVE
          ? UpgradeableState.ActiveContract
          : contract.contract_status === ContractStatusEnum.EXPIRED
            ? UpgradeableState.Expired
            : UpgradeableState.NoContract,
      service_partner_info: contract.service_partner_info,
      isSccValid: false,
    }));
    upgradableContracts = _mergeContracts(upgradableContracts, mergeContracts);
  }

  if (expiredSoonContracts.length) {
    const mergeContracts = expiredSoonContracts.map((contract) => ({
      carrier: contract.carrier,
      equipment_number: contract.equipment_number,
      equipment_type_icon: contract.equipment_type_icon,
      equipment: contract.equipment,
      telematic_state:
        contract.is_retrofittable &&
        !contract.has_telematics &&
        !isForJobPlanner
          ? TelematicState.Retrofitable
          : contract.has_telematics
            ? TelematicState.Telematic
            : TelematicState.NoTelematic,
      upgradeable_state: UpgradeableState.ExpiredSoon,
      service_partner_info: contract.service_partner_info,
      isSccValid: false,
    }));
    upgradableContracts = _mergeContracts(upgradableContracts, mergeContracts);
  }

  if (expiredContracts.length) {
    const mergeContracts = expiredContracts.map((contract) => ({
      carrier: contract.carrier,
      equipment_number: contract.equipment_number,
      equipment_type_icon: contract.equipment_type_icon,
      equipment: contract.equipment,
      telematic_state:
        contract.is_retrofittable &&
        !contract.has_telematics &&
        !isForJobPlanner
          ? TelematicState.Retrofitable
          : contract.has_telematics
            ? TelematicState.Telematic
            : TelematicState.NoTelematic,
      upgradeable_state: UpgradeableState.Expired,
      service_partner_info: contract.service_partner_info,
      isSccValid: false,
    }));
    upgradableContracts = _mergeContracts(upgradableContracts, mergeContracts);
  }

  if (noContractContracts.length) {
    const mergeContracts = noContractContracts.map((contract) => ({
      carrier: contract.carrier,
      equipment_number: contract.equipment_number,
      equipment_type_icon: contract.equipment_type_icon,
      equipment: contract.equipment,
      telematic_state:
        contract.is_retrofittable &&
        !contract.has_telematics &&
        !isForJobPlanner
          ? TelematicState.Retrofitable
          : contract.has_telematics
            ? TelematicState.Telematic
            : TelematicState.NoTelematic,
      upgradeable_state: UpgradeableState.NoContract,
      service_partner_info: contract.service_partner_info,
      isSccValid: false,
    }));
    upgradableContracts = _mergeContracts(upgradableContracts, mergeContracts);
  }

  return upgradableContracts;
}

export function generateEmailText(
  contractStatusInfo: UpgradeableContracts,
  translate: TranslateService,
  company_name?: string | null,
) {
  return (
    'mailto:' +
    (contractStatusInfo?.service_partner_info?.email || '') +
    '?subject=' +
    translate.instant(
      contractStatusInfo.telematic_state === TelematicState.Retrofitable
        ? 'connected.contract.email.subject_retrofittable'
        : 'connected.contract.email.subject',
      {
        equipment_number: contractStatusInfo.equipment_number,
      },
    ) +
    '&body=' +
    (contractStatusInfo?.service_partner_info?.name
      ? translate.instant('connected.contract.email.greeting', {
          service_partner_name: _replaceAmpersand(
            contractStatusInfo?.service_partner_info?.name,
          ),
        })
      : translate.instant('connected.contract.email.fallback_greeting')) +
    translate.instant(
      contractStatusInfo.telematic_state === TelematicState.Retrofitable
        ? 'connected.contract.email.body_retrofittable'
        : 'connected.contract.email.body',
      {
        equipment_number: contractStatusInfo.equipment_number,
      },
    ) +
    translate.instant('connected.contract.email.closing', {
      company_name: company_name ? _replaceAmpersand(company_name) : '',
    })
  );
}

function _mergeContracts(
  listA: UpgradeableContracts[],
  listB: UpgradeableContracts[],
): UpgradeableContracts[] {
  return listA.concat(
    listB.filter(
      (a) => !listA.find((b) => b.equipment_number === a.equipment_number),
    ),
  );
}

function _replaceAmpersand(text: string) {
  return text.replace(/&/g, '%26');
}

function filterByConnectedContract(
  contracts: ContractsOverviewResponse[],
  isForJobPlanner = false,
): ContractsOverviewResponse[] {
  return contracts.filter(
    (contract) =>
      contract?.contract_category === ContractCategory.Connected &&
      (isForJobPlanner
        ? isForJobPlanner
        : contract.is_retrofittable || contract.has_telematics),
  );
}

function filterByRetrofittableEquipment(
  contracts: ContractsOverviewResponse[],
): ContractsOverviewResponse[] {
  return contracts.filter(
    (contract) => contract.is_retrofittable && !contract.has_telematics,
  );
}

function filterByConnectedPlusContract(
  contracts: ContractsOverviewResponse[],
): ContractsOverviewResponse[] {
  return contracts.filter(
    (contract) =>
      contract?.contract_type === ConnectedContractTypes.CONNECTED_PLUS ||
      contract?.contract_type === ConnectedContractTypes.CONNECTED_PLUS_TRIAL ||
      contract?.contract_type ===
        ConnectedContractTypes.CONNECTED_PLUS_EARLY_ADOPTER,
  );
}

function filterByActiveConnectedPlusContracts(
  contracts: ContractsOverviewResponse[],
): ContractsOverviewResponse[] {
  const now = new DateTime();
  const threeMonths = new DateTime().set('month', now.month() + 2);
  return contracts.filter(
    (contract) =>
      contract.contract_end_date &&
      new DateTime(contract.contract_end_date).isSameOrAfter(threeMonths),
  );
}

function filterByActiveConnectedPlusTrialContract(
  contracts: ContractsOverviewResponse[],
): ContractsOverviewResponse[] {
  return contracts.filter(
    (contract) =>
      contract?.contract_type === ConnectedContractTypes.CONNECTED_PLUS_TRIAL &&
      contract.contract_status === ContractStatusEnum.ACTIVE,
  );
}

function filterByExpireSoonContract(
  contracts: ContractsOverviewResponse[],
): ContractsOverviewResponse[] {
  const now = new DateTime();
  const threeMonths = new DateTime().set('month', now.month() + 2);
  return contracts.filter(
    (contract) =>
      contract.contract_end_date &&
      new DateTime(contract.contract_end_date).isBetween(
        now,
        threeMonths,
        'day',
      ),
  );
}

function filterByExpiredContract(
  contracts: ContractsOverviewResponse[],
): ContractsOverviewResponse[] {
  return contracts.filter(
    (contract) => contract.contract_status === ContractStatusEnum.EXPIRED,
  );
}

function filterByNoContract(
  contracts: ContractsOverviewResponse[],
): ContractsOverviewResponse[] {
  return contracts.filter(
    (contract) =>
      contract.contract_type !== ConnectedContractTypes.CONNECTED_PLUS &&
      contract.contract_type !== ConnectedContractTypes.CONNECTED_PLUS_TRIAL &&
      contract?.contract_type !==
        ConnectedContractTypes.CONNECTED_PLUS_EARLY_ADOPTER,
  );
}
