<h2 mat-dialog-title data-cy="info-headline">
  {{ 'palchart.how_to_use_the_tool' | translate }}
</h2>
<mat-dialog-content>
  <ul data-cy="info-list">
    <li *ngFor="let i of [4, 5, 6, 7, 8]">
      {{ 'palchart.disclaimer.line' + i | translate }}
    </li>
  </ul>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    data-cy="info-close-button"
    mat-raised-button
    mat-dialog-close
    [gaClickListener]="{
      category: 'Job Planner - Info Dialog',
      action: 'Close Dialog'
    }"
  >
    {{ 'common.close' | translate }}
  </button>
</mat-dialog-actions>
