import { DestroyRef, Injectable, NgZone } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location as AngularLocation } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  InAppBrowser,
  InAppBrowserEvent,
  InAppBrowserObject,
} from '@awesome-cordova-plugins/in-app-browser/ngx'; //TODO: replace with '@capacitor/in-app-browser'
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import {
  DsSnackbarService,
  DsSnackbarType,
} from '@design-system/feature/snackbar';
import { UserService } from '@features/auth';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class MobileSetupService {
  //in app browser
  private _iabObject: InAppBrowserObject;
  private _bufferedUrl: string;
  private readonly _whitelist: readonly string[] = [
    environment.LINKS.SECURITY_TOKEN_SERVICE,
    'login.microsoftonline.com',
    'http://operatormonitor',
    'capacitor://operatormonitor',
  ];
  //other
  private _setUpCompleted = false;

  private isInWhitelist(url: string) {
    for (const whl of this._whitelist) {
      if (url.includes(whl)) return true;
    }
    return false;
  }

  /**
   *
   * @param state State during load error. error codes on ios: https://developer.apple.com/documentation/foundation/1508628-url_loading_system_error_codes/nsurlerrorunsupportedurl android: https://developer.android.com/reference/android/webkit/WebViewClient
   * @returns true if the error can be ignored, false if the error should close the inAppBrowser
   */
  private checkIOSError(state: InAppBrowserEvent): boolean {
    const urlIsEmptyString = state.url === '';
    const cannotFindHost = state.code === -1003; //often happens during a redirect
    const badUrl = state.code === -1002; //often happens during a redirect

    return urlIsEmptyString || cannotFindHost || badUrl;
  }

  /**
   * Overrides the upenuri function from OAuthService's config to instead open the login page in a
   * Cordova InAppBrowser and handle the login. To use the cordova InAppBrowser, import from
   * \@awesome-cordova-plugins/in-app-browser/ngx. If not added yet, use 'yarn add \@awesome-cordova-plugins/in-app-browser'
   * and 'yarn add cordova-plugin-inappbrowser'. Also add InAppBrowser to Providers
   * @param inAppBrowser Injected cordova InAppBrowser
   * @param iabObject InAppBrowserObject from cordova InAppBrowser. THIS VARIABLE MUST NOT BE DELETED!
   * @param userService Injected UserService. Used for login error handling.
   * @param appUrl URL used by the web view. NOTE: In Capacitor, Android uses http:// while IOS uses capacitor://
   * @param logoutUrl URL used for logging out the user.
   * @param _location Location Object from \@angular/common. Used to change the URL without navigating/routing, since routing is not necessary
   */
  private setupMobileLogin(
    inAppBrowser: InAppBrowser,
    iabObject: InAppBrowserObject,
    userService: UserService,
    appUrl: string,
    logoutUrl: string,
    _location: AngularLocation,
  ) {
    // eslint-disable-next-line sonarjs/cognitive-complexity
    userService.setOpenUri((uri: string) => {
      this._bufferedUrl = _location.path();
      iabObject = inAppBrowser.create(uri + '&hide_links=true', '_blank', {
        location: 'no',
        zoom: 'no',
        clearcache: 'yes',
      });
      //event triggers shortly after any url is opened in the in app browser. the observable returned by iabObject.on will disappear if iabObject is deleted.
      iabObject.on('loadstart').subscribe(async (state: InAppBrowserEvent) => {
        this._ngZone.run(async () => {
          if (!this.isInWhitelist(state.url)) {
            iabObject.executeScript({ code: 'window.history.back()' });
            window.open(state.url);
            return;
          }

          if (state.url.includes(appUrl + '/callback')) {
            iabObject.close();
            //replace URL so the OAuthService can find the necessary params
            _location.replaceState(state.url.replace(appUrl, ''));
            const loginResult =
              await userService.requestTokensIfRedirectFromAuthorizationServer();
            _location.replaceState(this._bufferedUrl);
            if (loginResult) {
              this._snackbar.queue(
                this._translateService.instant('mobile_sign_in_success'),
                { type: DsSnackbarType.Success },
              );
            } else {
              this._snackbar.queue(
                this._translateService.instant('mobile_sign_in_error'),
                { type: DsSnackbarType.Error },
              );
            }
          }
          if (state.url === logoutUrl) {
            iabObject.close();
          }
        });
      });

      iabObject.on('loaderror').subscribe(async (state: InAppBrowserEvent) => {
        this._ngZone.run(() => {
          if (
            state.url.includes(logoutUrl) ||
            state.url.includes(appUrl + '/callback') ||
            this.checkIOSError(state)
          )
            return;
          if (!this.isInWhitelist(state.url)) return;
          iabObject.close();
          this._snackbar.queue(
            this._translateService.instant(
              'palcode.general.sign_in_page_load_failed',
            ),
            {
              type: DsSnackbarType.Error,
            },
          );
        });
      });
    });
  }

  public setUpMobileApp() {
    const platform = Capacitor.getPlatform();

    if (platform === 'web' || this._setUpCompleted) return;

    App.addListener('backButton', () => {
      this._ngZone.run(() => {
        this._activatedRoute.url
          .pipe(take(1), takeUntilDestroyed(this._destroyRef))
          .subscribe((url) => {
            if (this._location.path(false).includes('/callback')) {
              return;
            } else if (!(url[0].path === 'search' && url.length === 1)) {
              this._location.back();
            } else {
              App.minimizeApp();
            }
          });
      });
    });

    this.setupMobileLogin(
      this._inAppBrowser,
      this._iabObject,
      this._userService,
      platform === 'ios'
        ? 'capacitor://operatormonitor'
        : 'http://operatormonitor',
      'http://operatormonitor/landing',
      this._location,
    );
    this._setUpCompleted = true;
  }

  public isSetupCompleted() {
    return this._setUpCompleted;
  }

  constructor(
    private _ngZone: NgZone,
    private _inAppBrowser: InAppBrowser,
    private _userService: UserService,
    private _location: AngularLocation,
    private _snackbar: DsSnackbarService,
    private _translateService: TranslateService,
    private _destroyRef: DestroyRef,
    private _activatedRoute: ActivatedRoute,
  ) {}
}
