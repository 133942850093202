<opm-footer [class.native-padding]="isNative">
  <div fxLayout fxLayoutAlign="space-between center">
    @if (hasInfoButton) {
      <button
        mat-button
        data-cy="job-planner-info"
        (click)="openInfo()"
        fxLayout
        fxLayoutAlign="center center"
        [fxLayoutGap]="0.5 | dsSpacing"
        class="no-underline"
        [gaClickListener]="'Open How To dialog'"
      >
        <mat-icon>info</mat-icon>
        <span>{{ 'palchart.how_to_use_the_tool' | translate }}</span>
      </button>
    }

    @if (hasPreviousButton) {
      <button
        mat-raised-button
        (click)="backClick.emit()"
        data-cy="previous-button"
        [gaClickListener]="'Navigate to previous step'"
      >
        {{ 'common.previous_button' | translate }}
      </button>
    }
    <button
      data-cy="next-button"
      mat-raised-button
      color="primary"
      (click)="nextClick.emit()"
      [disabled]="isDisabled"
      [gaClickListener]="isLastStep ? 'Calculate' : 'Navigate to next Step'"
    >
      {{
        (isLastStep ? 'palchart.calculated_curve' : 'common.next_button')
          | translate
      }}
    </button>
  </div></opm-footer
>
