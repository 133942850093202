<h1 mat-dialog-title>
  {{ 'install_prompt.headline' | translate }}
</h1>
<mat-dialog-content>
  <span>
    <div
      class="icon-helper"
      fxLayout
      fxLayoutAlign="space-between center"
      fxLayoutGap="8px"
    >
      <div></div>
      <div></div>
      <img src="assets/icons/icon-72x72.png" />
      <div></div>
      <div></div>
    </div>
    <p>
      {{ 'install_prompt.text' | translate }}
    </p>
  </span>
  <span class="full-width share-text">
    <span> {{ 'install_prompt.tab_action' | translate }}</span>
    <img class="share-icon" src="assets/images/share.png" />
    <span> {{ 'install_prompt.add_action' | translate }}</span>
  </span>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button matDialogClose>{{ 'general.cancel' | translate }}</button>
</mat-dialog-actions>
