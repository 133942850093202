import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { TranslateModule } from '@ngx-translate/core';
import { GoogleAnalytics4DirectiveModule } from '@shared-lib/google-analytics';
import { JobPlannerInfoComponent } from '../shared/components/job-planner-info/job-planner-info.component';
import { FooterComponent } from '../../shared/components/footer/footer.component';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'opm-job-planner-footer',
  templateUrl: './job-planner-footer.component.html',
  styleUrls: ['./job-planner-footer.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FlexLayoutModule,
    GoogleAnalytics4DirectiveModule,
    DsSpacingModule,
    MatIconModule,
    MatButtonModule,
    FooterComponent,
  ],
})
export class JobPlannerFooterComponent {
  @Input() isLastStep = false;
  @Input() hasPreviousButton = false;
  @Input() hasInfoButton = false;
  @Input() isDisabled = false;
  @Output() nextClick = new EventEmitter();
  @Output() backClick = new EventEmitter();

  isNative = Capacitor.isNativePlatform();

  constructor(private _dialog: MatDialog) {}

  openInfo() {
    this._dialog.open(JobPlannerInfoComponent, {
      width: '500px',
    });
  }
}
