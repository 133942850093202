import { createReducer, on } from '@ngrx/store';
import { FeatureFlagActions } from './feature-flag.actions';

export const featureFlagKey = 'featureFlag';

export interface FeatureFlagState {
  isInfoMessageShown: boolean;
  isJobPlannerV2Enabled: boolean;

  isJobPlannerPreviewUser: boolean;
  isSignalREnabled: boolean;
}

export const initialFeatureFlagState: FeatureFlagState = {
  isInfoMessageShown: false,
  isJobPlannerV2Enabled: false, // PR HINT: This needs to be false otherwise jp v2 will go on prod
  isJobPlannerPreviewUser: false,
  isSignalREnabled: false,
};

const _featureFlag = createReducer(
  initialFeatureFlagState,

  on(FeatureFlagActions.loadShowMessageSuccess, (state, { payload }) => ({
    ...state,
    isInfoMessageShown: payload,
  })),
  on(FeatureFlagActions.loadShowMessageError, (state) => ({
    ...state,
    isInfoMessageShown: false,
  })),
  on(
    FeatureFlagActions.loadJobPlannerV2FeatureSuccess,
    (state, { payload }) => ({
      ...state,
      isJobPlannerV2Enabled: payload,
    }),
  ),
  on(FeatureFlagActions.loadJobPlannerV2FeatureError, (state) => ({
    ...state,
    isJobPlannerV2Enabled: false,
  })),
  on(FeatureFlagActions.loadSignalRSuccess, (state, { payload }) => ({
    ...state,
    isSignalREnabled: payload,
  })),
  on(FeatureFlagActions.loadSignalRError, (state) => ({
    ...state,
    isSignalREnabled: false,
  })),
  on(FeatureFlagActions.setPreviewUser, (state, { isPreviewUser }) => ({
    ...state,
    isJobPlannerPreviewUser: isPreviewUser,
  })),
);

export function featureFlagReducer(state, action) {
  return _featureFlag(state, action);
}
