import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Capacitor } from '@capacitor/core';
import { UserService } from '@features/auth';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/operators';
interface PromoTiles {
  name: string;
  link?: string;
  image?: string;
  description: string;
}
@Component({
  selector: 'opm-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit, OnDestroy {
  promoVideo = 'https://www.youtube.com/watch?v=3IdSfrH4DLg&feature=emb_logo';

  freeFeatures: PromoTiles[] = [
    {
      name: 'menu_item.service_contact',
      link: '/salesandservicesearch',
      image: 'assets/images/service_partner.png',
      description: 'dashboard.sales_description',
    },
    {
      name: 'menu_item.alerts',
      link: '/codes/finder',

      image: 'assets/images/status_codes.png',
      description: 'dashboard.alerts_description',
    },
  ];
  registeredFeatures: PromoTiles[] = [
    {
      name: 'menu_item.dashboard',
      image: 'assets/images/dashboard.png',
      description: 'dashboard.dashboard_description',
    },
    {
      name: 'menu_item.maintentance_checklist',
      image: 'assets/images/mcl.png',
      description: 'dashboard.mcl_description',
    },
    {
      name: 'menu_item.palchart',
      image: 'assets/images/job_planner.png',
      description: 'dashboard.job_planner_description',
    },
  ];

  private readonly destroy$ = new Subject<void>();
  private isNative = Capacitor.isNativePlatform();

  constructor(
    public userService: UserService,
    private router: Router,
    private _inAppBrowser: InAppBrowser,
  ) {}

  ngOnInit() {
    this.userService.isAuthorized$
      .pipe(takeUntil(this.destroy$))
      .subscribe((isAuthorized) => {
        if (isAuthorized) this.router.navigate(['/dashboard']);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  openCreateAccount() {
    if (this.isNative) {
      this._inAppBrowser.create(
        'https://identity.palfinger.com/Registration',
        '_blank',
        {
          location: 'no',
          zoom: 'no',
          clearcache: 'no',
        },
      );
    } else {
      window.location.href = 'https://identity.palfinger.com/Registration';
    }
  }
}
