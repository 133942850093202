import { Pipe, PipeTransform } from '@angular/core';
import { JobStabilizerData } from '@connected/data-access/fleet-service';
import { SupportPointsLight } from '@paldesk/shared-lib/data-access/palchart-service-generated';

@Pipe({
  name: 'getSupportPointsByType',
  standalone: true,
})
export class GetSupportPointsByTypePipe implements PipeTransform {
  transform(
    supportPoints: Array<SupportPointsLight | JobStabilizerData>,
    type: 'Stabilizer' | 'Wheel',
  ): SupportPointsLight[] | JobStabilizerData[] | undefined {
    if (supportPoints && type) {
      return supportPoints?.filter((x) => x.type?.indexOf(type) !== -1) || [];
    }
  }
}
