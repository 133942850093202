/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable no-console */
import { Inject, Injectable, Optional } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { Observable } from 'rxjs';
import { BASE_PATH } from './variables';
import { OperatingMinutesResponse, LocationChangeResponse } from './models';

@Injectable({
  providedIn: 'root',
})
export class SignalRService {
  private _hubConnection: signalR.HubConnection;
  private _basePath: string;

  constructor(@Optional() @Inject(BASE_PATH) private basePath: string | null) {
    if (basePath) {
      this._basePath = basePath;
    }
  }

  public configureConnection(jwtTokken: string): void {
    this._hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(this._basePath + '/connectedHub', {
        accessTokenFactory: () => jwtTokken,
        transport: signalR.HttpTransportType.LongPolling,
        withCredentials: true,
      })
      .build();
  }

  public startFleetConnection(): void {
    if (this._hubConnection.state === signalR.HubConnectionState.Connected) {
      this._hubConnection
        .stop()
        .then(() => {
          console.log('SignalR connection stopped');
          this._hubConnection
            .start()
            .then(() => {
              console.log('SignalR connection started for fleet');
              this._hubConnection.invoke('AddToCompanySapIdGroup');
            })
            .catch((err) =>
              console.log('Error while starting SignalR connection: ' + err),
            );
        })
        .catch((err) =>
          console.log('Error while stopping SignalR connection: ' + err),
        );
    } else {
      this._hubConnection
        .start()
        .then(() => {
          console.log('SignalR connection started for fleet');
          this._hubConnection.invoke('AddToCompanySapIdGroup');
        })
        .catch((err) =>
          console.log('Error while starting SignalR connection: ' + err),
        );
    }
  }

  public startEquipmentConnection(equipmentNumber: string): void {
    if (this._hubConnection.state === signalR.HubConnectionState.Connected) {
      this._hubConnection
        .stop()
        .then(() => {
          console.log('SignalR connection stopped');

          this._hubConnection
            .start()
            .then(() => {
              console.log(
                `SignalR connection started for equipment ${equipmentNumber}`,
              );
              this._hubConnection.invoke(
                'AddToEquipmentNumberGroup',
                equipmentNumber,
              );
            })
            .catch((err) =>
              console.log('Error while starting SignalR connection: ' + err),
            );
        })
        .catch((err) =>
          console.log('Error while stopping SignalR connection: ' + err),
        );
    } else {
      this._hubConnection
        .start()
        // eslint-disable-next-line sonarjs/no-identical-functions
        .then(() => {
          console.log(
            `SignalR connection started for equipment ${equipmentNumber}`,
          );
          this._hubConnection.invoke(
            'AddToEquipmentNumberGroup',
            equipmentNumber,
          );
        })
        .catch((err) =>
          console.log('Error while starting SignalR connection: ' + err),
        );
    }
  }

  public alertsListener(): Observable<string> {
    console.log('Alerts listener started');
    this._hubConnection.off('alerts');
    return new Observable((observer) => {
      this._hubConnection.on('alerts', (message: string) => {
        console.log('Message received:', message);
        observer.next(message);
      });
    });
  }
  public stopAlertsListener(): void {
    console.log('Stop Alerts listener');
    this._hubConnection.off('alerts');
  }

  public operatingMinutesListener(): Observable<OperatingMinutesResponse> {
    console.log('Operating Minutes listener started');
    this._hubConnection.off('EquipmentOperatingMinutesChangedEvent');
    return new Observable((observer) => {
      this._hubConnection.on(
        'EquipmentOperatingMinutesChangedEvent',
        (message: string) => {
          console.log('Message received:', message);
          observer.next(JSON.parse(message));
        },
      );
    });
  }
  public stopOperatingMinutesListener(): void {
    console.log('Stop Operating Minutes listener');
    this._hubConnection.off('EquipmentOperatingMinutesChangedEvent');
  }

  public locationListener(): Observable<LocationChangeResponse> {
    console.log('Location listener started');
    this._hubConnection.off('EquipmentLocationChangedEvent');
    return new Observable((observer) => {
      this._hubConnection.on(
        'EquipmentLocationChangedEvent',
        (message: string) => {
          console.log('Message received:', message);
          observer.next(JSON.parse(message));
        },
      );
    });
  }
  public stopLocationListener(): void {
    console.log('Stop Location listener');
    this._hubConnection.off('EquipmentLocationChangedEvent');
  }
}
