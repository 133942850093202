import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { ContractsActions } from './contracts.actions';
import { filterUpgradableContracts, getConnectedContracts } from '../helper';
import {
  ContractData,
  ContractsFilterSetup,
  ContractStatusEnum,
  TelematicState,
  UpgradeableContracts,
} from '../models';
import { Sort } from '@angular/material/sort';
import {
  ContractsOverviewResponse,
  DueDateStatus,
} from '@connected/fleet-service/models';
import { mapPPI2PPG } from '@connected/shared/methods';
import { DateTime } from '@paldesk/shared-lib/utils/date-utils';

const contractsKey = 'contracts';

export interface ContractsState {
  contractsOverview: ContractsOverviewResponse[];
  isContractsOverviewLoading: boolean;
  hasContractsOverviewFailed: boolean;

  contractOverviewfilterSetup: ContractsFilterSetup;
  contractOverviewSort: Sort;

  upgradableContracts: UpgradeableContracts[];
  upgradableJobPlannerContracts: UpgradeableContracts[];
  connectedContracts: ContractsOverviewResponse[];
}

export const contractsInitialState: ContractsState = {
  contractsOverview: [],
  isContractsOverviewLoading: true,
  hasContractsOverviewFailed: false,
  contractOverviewfilterSetup: {},
  contractOverviewSort: {
    active: 'carrier',
    direction: 'desc',
  },
  upgradableContracts: [],
  upgradableJobPlannerContracts: [],
  connectedContracts: [],
};

const _contracts = createReducer(
  contractsInitialState,
  on(ContractsActions.LoadContracts, (state) => ({
    ...state,
    isContractsOverviewLoading: true,
    hasContractsOverviewFailed: false,
  })),
  on(ContractsActions.LoadContractsSuccess, (state, { payload }) => ({
    ...state,
    contractsOverview: payload?.map((item) =>
      mapPPI2PPG(item, 'equipment_type_icon'),
    ),
    upgradableContracts: filterUpgradableContracts(payload),
    upgradableJobPlannerContracts: filterUpgradableContracts(payload, true),
    connectedContracts: getConnectedContracts(payload),
    isContractsOverviewLoading: false,
    hasContractsOverviewFailed: false,
  })),
  on(ContractsActions.LoadContractsError, (state) => ({
    ...state,
    isContractsOverviewLoading: false,
    hasContractsOverviewFailed: true,
  })),

  on(ContractsActions.SetContractsFilter, (state, { payload }) => ({
    ...state,
    contractOverviewfilterSetup: payload,
  })),
  on(ContractsActions.SaveContractOverviewSort, (state, { payload }) => ({
    ...state,
    contractOverviewSort: payload,
  })),
);

function _contractsReducer(state, action) {
  return _contracts(state, action);
}

export const ContractsFeature = createFeature({
  name: contractsKey,
  reducer: _contractsReducer,
  extraSelectors: ({ selectUpgradableContracts, selectContractsOverview }) => ({
    selectUpgradableEquipment: (equipmentNumber: string) =>
      createSelector(selectUpgradableContracts, (upgradableContracts) =>
        upgradableContracts.find(
          (contract) => contract.equipment_number === equipmentNumber,
        ),
      ),
    selectEquipmentContracts: (equipmentNumber: string) =>
      createSelector(selectContractsOverview, (contracts) =>
        equipmentNumber?.length
          ? getContractDataInfos(
              contracts.filter(
                (contract) => contract.equipment_number === equipmentNumber,
              ),
            )
          : [],
      ),
    selectRetrofitableEquipments: createSelector(
      selectUpgradableContracts,
      (upgradableContracts) =>
        upgradableContracts.filter(
          (contract) =>
            contract.telematic_state === TelematicState.Retrofitable,
        ),
    ),
  }),
});

function getContractDataInfos(
  contractInfo: ContractsOverviewResponse[],
): ContractData[] {
  return contractInfo
    .filter((contract) => contract.contract_type !== 'None')
    .map((contract) => {
      const startDate = new DateTime(
        contract?.contract_start_date || undefined,
      );
      const endDate = new DateTime(contract?.contract_end_date || undefined);
      const today = new DateTime();

      const percentage = endDate.isSameOrAfter(today)
        ? (today.diff(startDate) / endDate.diff(startDate)) * 100
        : 100;

      return {
        ...contract,
        dueDateStatus: getDueDateStatus(
          contract.contract_status === ContractStatusEnum.EXPIRED,
          percentage,
        ),
        percentage: percentage,
        value: DateTime.durationHumanized(today.diff(endDate)),
      };
    });
}

function getDueDateStatus(isExpired: boolean, percentage: number) {
  return isExpired
    ? DueDateStatus.OverDue
    : percentage > 80
      ? DueDateStatus.DueSoon
      : DueDateStatus.Operational;
}
