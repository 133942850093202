<mat-card [ngClass]="isInDiagnosticMode ? 'info' : 'warning'">
  <mat-card-content fxLayout fxLayoutAlign="space-between center">
    <!-- Label with Count -->
    <span data-cy="dashboard-palcodes-count">
      {{
        isInDiagnosticMode
          ? ('common.diagnostic_mode' | translate)
          : ('dashboard.alerts_label' | translate: { count: count })
      }}
    </span>
    <!-- Show Errors Action -->
    @if (!isInDiagnosticMode && count) {
      <button
        mat-raised-button
        [routerLink]="alertsLink"
        data-cy="dashboard-current-palcodes"
        [gaClickListener]="{
          category: 'Palcode Pop-Up',
          action: 'Navigate to active Palcode'
        }"
      >
        {{ 'dashboard.alerts_button' | translate }}
      </button>
    }
  </mat-card-content>
</mat-card>
