import { Injectable, inject } from '@angular/core';
import {
  DsSnackbarService,
  DsSnackbarType,
} from '@design-system/feature/snackbar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
  CustomerDocumentsService,
  EquipmentService,
} from '@connected/data-access/fleet-service';
import { filterTruthy } from '@shared-lib/rxjs';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { OperatorMonitorState } from '..';
import { HeaderSelectors } from '../header';
import { DocumentsActions } from './documents.actions';

@Injectable()
export class DocumentsEffects {
  private actions$: Actions = inject(Actions);
  private _store: Store<OperatorMonitorState> = inject(Store);

  LoadCustomerDocuments$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        DocumentsActions.LoadCustomerDocuments,
        DocumentsActions.UploadCustomerDocumentsSuccess,
        DocumentsActions.DeleteCustomerDocumentsSuccess,
      ),
      switchMap(({ payload }) =>
        this.customerDocumentService.getDocuments(payload).pipe(
          map((data) =>
            DocumentsActions.LoadCustomerDocumentsSuccess({
              payload: data,
            }),
          ),
          catchError(() => [DocumentsActions.LoadCustomerDocumentsError()]),
        ),
      ),
    ),
  );

  deleteCustomerDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentsActions.DeleteCustomerDocuments),
      withLatestFrom(
        this._store.pipe(
          select(HeaderSelectors.getSelectedEquipmentNumber),
          filterTruthy(),
        ),
      ),
      switchMap(([{ payload }, equipment_number]) =>
        this.customerDocumentService
          .deleteDocuments(payload, equipment_number)
          .pipe(
            map(() =>
              DocumentsActions.DeleteCustomerDocumentsSuccess({
                payload: equipment_number,
                document_id: payload,
              }),
            ),
            catchError(() => {
              this._snackbar.queue(
                this._translateService.instant('common.error.general'),
                {
                  type: DsSnackbarType.Error,
                },
              );
              return of(
                DocumentsActions.DeleteCustomerDocumentsError({
                  payload: payload,
                }),
              );
            }),
          ),
      ),
    ),
  );

  uploadCustomerDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentsActions.UploadCustomerDocuments),
      withLatestFrom(
        this._store.pipe(
          select(HeaderSelectors.getSelectedEquipmentNumber),
          filterTruthy(),
        ),
      ),
      switchMap(([action, equipment_number]) =>
        this.customerDocumentService
          .uploadDocuments(equipment_number, undefined, action.payload)
          .pipe(
            map(() =>
              DocumentsActions.UploadCustomerDocumentsSuccess({
                payload: equipment_number,
              }),
            ),
            catchError(() => {
              this._snackbar.queue(
                this._translateService.instant('common.error.general'),
                {
                  type: DsSnackbarType.Error,
                },
              );
              return of(DocumentsActions.UploadCustomerDocumentsError());
            }),
          ),
      ),
    ),
  );

  loadDocuments$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentsActions.LoadDocuments),
      withLatestFrom(
        this._store.pipe(
          select(HeaderSelectors.getSelectedEquipmentNumber),
          filterTruthy(),
        ),
      ),
      switchMap(([, equipmentNumber]) =>
        this.equipmentService.getHybrisEquipment(equipmentNumber).pipe(
          map((data) =>
            DocumentsActions.LoadDocumentsSuccess({
              payload: data,
            }),
          ),
          catchError(() => [DocumentsActions.LoadDocumentsError()]),
        ),
      ),
    ),
  );

  constructor(
    private customerDocumentService: CustomerDocumentsService,
    private equipmentService: EquipmentService,
    private _snackbar: DsSnackbarService,
    private _translateService: TranslateService,
  ) {}
}
