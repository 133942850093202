<h1 mat-dialog-title>{{ 'general.select_language' | translate }}</h1>
<mat-dialog-content>
  <shared-lib-language-selection
    [availableLanguages]="availableLanguages"
    [defaultLanguageCode]="userLanguage"
    [placeholder]="'general.select_language' | translate"
    [isMaterial]="true"
    (selectLanguage)="changeUserLanguage($event)"
  >
  </shared-lib-language-selection>
</mat-dialog-content>
<mat-dialog-actions fxLayoutAlign="end">
  <button
    mat-button
    (click)="dialogRef.close()"
    [gaClickListener]="{
      category: 'Language Selection Dialog',
      action: 'Close Dialog'
    }"
  >
    {{ 'general.cancel' | translate }}
  </button>
</mat-dialog-actions>
