import {
  HybrisEquipmentResponse,
  PalipediaDocumentResponseDto,
  PictureResponseDto,
} from '@connected/fleet-service/models';
import {
  CategoryNode,
  PalipediaDocument,
  PalipediaEquipmentWithDocuments,
  PalipediaFile,
  PalipediaGraphicsArchiveClassWithItems,
  PalipediaPicture,
  PalipediaProductOrCategoryWithDocuments,
} from '@features/palipedia';
import {
  ApiCategoryWithDocuments,
  ApiDocument,
  ApiDocumentPicture,
  ApiGraphicsArchiveClassWithItems,
  ApiProductWithDocuments,
} from '@paldesk/shared-lib/data-access/palipedia-service-generated';

export function mapFleetMonitorPalipediaToPalipediaEquipmentWithDocuments(
  input: HybrisEquipmentResponse,
): PalipediaEquipmentWithDocuments {
  return {
    palfingerDocuments:
      input?.palfinger_documents?.map((document) =>
        mapFleetMonitorPalipediaToPalipediaDocument(
          document,
          input.equipment_number,
        ),
      ) || null,
    partnerDocuments:
      // eslint-disable-next-line sonarjs/no-identical-functions
      input?.partner_documents?.map((document) =>
        mapFleetMonitorPalipediaToPalipediaDocument(
          document,
          input.equipment_number,
        ),
      ) || null,
    pictures: input?.pictures.map(mapFleetMonitorPalipediaToPalipediaPicture),
    equipmentNumber: input?.equipment_number,
    videoEmbeddedCode: null,
  };
}

export function mapPalipediaApiProductOrCategoryToPalipediaProductOrCategoryWithDocuments(
  input: ApiProductWithDocuments | ApiCategoryWithDocuments,
): PalipediaProductOrCategoryWithDocuments {
  return <PalipediaEquipmentWithDocuments>{
    palfingerDocuments:
      input.palfinger_documents?.map((d) =>
        mapPalipediaApiDocumentToPalipediaDocument(d),
      ) || null,
    partnerDocuments:
      input.partner_documents?.map((d) =>
        mapPalipediaApiDocumentToPalipediaDocument(d),
      ) || null,
    pictures:
      input.pictures?.map(mapPalipediaApiPictureToPalipediaPicture) || null,
    equipmentNumber: null,
    videoEmbeddedCode: null,
  };
}

export function mapPalipediaApiCategoriesToPalipediaCategory(
  nodes: any[],
): CategoryNode[] {
  if (!nodes) return [];
  return nodes.map((category: any) => ({
    id: category.id,
    children: category.children
      ? mapPalipediaApiCategoriesToPalipediaCategory(category.children)
      : [],
    code: category.code,
    name: category.name,
    pk: category.hybris_pk,
    type: category.type,
    currently_produced: category.currently_produced,
    hybrisType: category.type,
  }));
}

export function mapPalipediaGraphicsArchiveToPalipediaGraphicArchiveItem(
  apiGraphicsArchiveClassWithItems: ApiGraphicsArchiveClassWithItems,
): PalipediaGraphicsArchiveClassWithItems {
  return {
    id: apiGraphicsArchiveClassWithItems.id,
    identifier: apiGraphicsArchiveClassWithItems.identifier,
    name: apiGraphicsArchiveClassWithItems.name || null,
    attribute_names: (
      apiGraphicsArchiveClassWithItems.attribute_names || []
    ).map((kvp) => ({
      identifier: kvp.key || '',
      name: kvp.value || '',
    })),
    items:
      apiGraphicsArchiveClassWithItems.items?.map((item) => ({
        id: item.id,
        graphic_url: item.graphic_url || null,
        thumbnail_url: item.thumbnail_url || null,
        attributes: item.attributes || null,
      })) || null,
  };
}

function mapFleetMonitorPalipediaToPalipediaPicture(
  pictureResponse: PictureResponseDto,
): PalipediaPicture {
  return {
    id: pictureResponse.id,
    pictureUrl: pictureResponse.picture_url,
    thumbnailUrl: pictureResponse.thumbnail_url,
    youtubeUrl: null,
  };
}

function mapPalipediaApiDocumentToPalipediaDocument(
  documentResponse: ApiDocument,
  equipmentNumber?: string,
): PalipediaDocument {
  return <PalipediaDocument>{
    id: documentResponse.id.toString(),
    identifier: documentResponse.identifier || null,
    version: documentResponse.version || null,
    title: documentResponse.name || null,
    documentClassCode: documentResponse.document_class_identifier || null,
    documentClassName: documentResponse.document_class_name || null,
    fromSerialNumber: documentResponse.from_serial_number || null,
    untilSerialNumber: documentResponse.to_serial_number || null,
    companyName: documentResponse.company_name || null,
    files: mapPalipediaApiDocumentToPalipediaFile(documentResponse),
    epcDocumentConfig: documentResponse.epc_document_config || null,
    equipmentNumber: equipmentNumber || null,
  };
}

function mapFleetMonitorPalipediaToPalipediaDocument(
  documentResponse: PalipediaDocumentResponseDto,
  equipmentNumber?: string | null,
): PalipediaDocument {
  return <PalipediaDocument>{
    id: documentResponse.id.toString(),
    identifier: documentResponse.id || null,
    version: documentResponse.version || null,
    title: documentResponse.name || null,
    documentClassCode: documentResponse.document_class_identifier || null,
    documentClassName: documentResponse.document_class_name || null,
    fromSerialNumber: documentResponse.from_serial_number || null,
    untilSerialNumber: documentResponse.to_serial_number || null,
    companyName: documentResponse.company_name || null,
    files: mapPalipediaApiDocumentToPalipediaFile(documentResponse),
    epcDocumentConfig: null,
    equipmentNumber: equipmentNumber || null,
  };
}

function mapPalipediaApiDocumentToPalipediaFile(
  documentResponse: ApiDocument | PalipediaDocumentResponseDto,
): PalipediaFile[] {
  const viewUrls: { [key: string]: string } = {};
  documentResponse.view_urls?.forEach(
    (url) =>
      (viewUrls[url.language?.toLocaleLowerCase() || ''] = url.url || ''),
  );

  const downloadUrls: { [key: string]: string } = {};
  if (documentResponse.download_urls?.length) {
    documentResponse.download_urls?.forEach(
      (url) =>
        (downloadUrls[url.language?.toLocaleLowerCase() || ''] = url.url || ''),
    );
  } else if ('supported_languages' in documentResponse) {
    // temporary fix for download of palipedia documents
    documentResponse.supported_languages?.forEach(
      (language) =>
        (downloadUrls[language?.toLocaleLowerCase() || ''] = 'PLACEHOLDER'),
    );
  }
  return [
    {
      view: viewUrls,
      download: downloadUrls,
      print: downloadUrls,
    },
  ];
}

function mapPalipediaApiPictureToPalipediaPicture(
  pictureResponse: ApiDocumentPicture,
): PalipediaPicture {
  return {
    id: pictureResponse.id || null,
    pictureUrl: pictureResponse.picture_url || null,
    thumbnailUrl: pictureResponse.thumbnail_url || null,
    youtubeUrl: null,
  };
}
