<span fxLayout="column" class="full-height">
  @if (isIos) {
    <span class="status-bar full-width"></span>
  }
  <mat-sidenav-container [class.native-padding]="isIos">
    <mat-sidenav #sidenav [autoFocus]="false">
      <opm-sidebar [isIos]="isIos" />
    </mat-sidenav>
    <mat-sidenav-content fxLayout="column" class="full-height">
      <opm-header
        [navItems]="navItems"
        [isOffline]="isOffline"
        (menuClick)="sidenav.open()"
        (offlineClick)="showOfflineInfo()"
        (noEquipmentClick)="showNoEquipmentInfo()"
        fxFlex="0 0 auto"
      />
      <div
        fxFlex="0 1 auto"
        class="content no-min-height scroll-container-vertical full-height"
        [class.no-bottom-padding]="location.path().includes('landing')"
        [class.no-padding]="location.path().includes('salesandservicesearch')"
      >
        <router-outlet />
      </div>
    </mat-sidenav-content> </mat-sidenav-container
></span>
