import { Pipe, PipeTransform } from '@angular/core';
import {
  IMPERIAL,
  METRIC,
  MeasurementSystem,
} from '../model/unit-config.model';
import { UserService } from '@features/auth';

@Pipe({
  name: 'calculateUserUnit',
  standalone: true,
})
export class CalculateUserUnitPipe implements PipeTransform {
  private _isUserUsingImperial = false;

  constructor(private _userService: UserService) {
    this._isUserUsingImperial = this._userService.userContext.imperial_units;
  }

  transform(
    value: number | null | undefined,
    type: 'distance' | 'weight',
    options: {
      toFixed?: number;
      usedSystem?: MeasurementSystem;
      fixedUnit?: string;
    } = {
      toFixed: 2,
    },
  ): string {
    const system = options.usedSystem
      ? options.usedSystem
      : this._isUserUsingImperial
        ? IMPERIAL
        : METRIC;
    const unitConfigs = Array.from(system[type]);
    // Limit units only to kg/m for metric and lbs/ft for imperial for now
    const limitedUnits = unitConfigs.filter((unit) =>
      options.fixedUnit
        ? unit.unit === options.fixedUnit
        : unit.unit === 'm' ||
          unit.unit === 'kg' ||
          unit.unit === 'lbs' ||
          unit.unit === 'ft',
    );

    let bestFittingUnit = limitedUnits[0];
    for (let i = 0; i < limitedUnits.length; i++) {
      if ((value ?? 0) * limitedUnits[i].factorToMetric >= 2) {
        bestFittingUnit = limitedUnits[i];
      } else {
        break;
      }
    }

    const convertedValue = (
      (value ?? 0) * (bestFittingUnit?.factorToMetric || 1)
    ).toFixed(Math.max(0, options.toFixed === undefined ? 2 : options.toFixed));
    return `${parseFloat(convertedValue)} ${bestFittingUnit?.unit}`;
  }
}
