import { createAction, props } from '@ngrx/store';

export namespace FeatureFlagActions {
  export const loadFeatureFlags = createAction(
    '[FEATURE FLAG] LOAD_FEATURE_FLAGS',
  );

  export const loadShowMessageSuccess = createAction(
    '[FEATURE FLAG] LOAD_SHOW_INFO_MESSAGE_SUCCESS',
    props<{ payload: boolean }>(),
  );
  export const loadShowMessageError = createAction(
    '[FEATURE FLAG] LOAD_SHOW_INFO_MESSAGE_ERROR',
  );

  export const loadJobPlannerV2FeatureSuccess = createAction(
    '[FEATURE FLAG] LOAD_JOB_PLANNER_V2_SUCCESS',
    props<{ payload: boolean }>(),
  );

  export const loadJobPlannerV2FeatureError = createAction(
    '[FEATURE FLAG] LOAD_JOB_PLANNER_V2_ERROR',
  );

  export const setPreviewUser = createAction(
    '[FEATURE FLAG] SET_PREVIEW_USER',
    props<{ isPreviewUser: boolean }>(),
  );

  export const loadSignalRSuccess = createAction(
    '[FEATURE FLAG] LOAD_SIGNAL_R_SUCCESS',
    props<{ payload: boolean }>(),
  );
  export const loadSignalRError = createAction(
    '[FEATURE FLAG] LOAD_SIGNAL_R_ERROR',
  );
}
